.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    display: flex;
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    /* background: transparent; */
}

.portfolio__item img {
    border-radius: 1.5rem;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    font-size: 1.4rem;
}

.portfolio__item h4 {
    margin: 1rem;
    /* margin-bottom: 1rem; */
    color: var(--color-primary-variant);
}

.portfolio__item h4 span {
    text-decoration: underline;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    /* margin-top: 1rem;  */
    margin-bottom: 1rem;
    justify-content: center;

}

/*Media Queries for medium */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

}

/*Media Queries for small */

@media screen and (max-width: 750px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

}