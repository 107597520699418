.container.recommendations__container {
    width: 40%;
    padding-bottom: 4rem;
}

.rec__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.recommendation {
    /* background: var(--color-bg-variant); */
    background: linear-gradient(transparent, var(--color-primary-variant));
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.rec__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
    height: 2rem;
    width: 2rem;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: var(--color-primary);
}


/*Media Queries for medium */

@media screen and (max-width: 1024px) {
    .container.recommendations__container {
        width: 60%
    }

}

/*Media Queries for small */

@media screen and (max-width: 600px) {
    .container.recommendations__container {
        width: var(--container-width-sm);
    }

    .rec__review {
        width: var(--container-width-sm);
    }

}