footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg)
}

.footer__logo {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: inline-block;
    color: var(--color-white);
}

.links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
    margin-bottom: 5rem;
    padding-bottom: 4rem;
}